import { createFocusTrap, FocusTrap } from 'focus-trap';
import moveFocus from '../../../javascripts/utils/moveFocus';

const createDesktopNavigation = (
  $navigation: HTMLDivElement,
): CallableFunction | undefined => {
  let focusTrap: FocusTrap;
  let $elementWithIndicator: HTMLElement | null = null;
  let $currentSubmenuTrigger: HTMLElement | null = null;

  // Get header elements
  const $header = $navigation.closest<HTMLDivElement>('.header');
  const $indicator = $header?.querySelector<HTMLDivElement>(
    '.header__navigation-indicator',
  );

  // Move indicator
  const moveIndicator = (
    $trigger: HTMLElement | null = null,
    $startingElement: HTMLElement | null = null,
  ) => {
    if (!$indicator) {
      return;
    }

    if ($trigger) {
      // Get left position of navigation
      const { left: navigationLeft } = $navigation.getBoundingClientRect();
      const { left: triggerLeft, width } = $trigger.getBoundingClientRect();
      const left = triggerLeft - navigationLeft;

      // We've got a starting element
      if ($startingElement) {
        // Get start element value
        const { left: startingElementLeft, width: startingElementWidth } =
          $startingElement.getBoundingClientRect();
        const startingPoint = startingElementLeft - navigationLeft;
        const backwarts = left - startingPoint <= 0;
        const translate = backwarts ? left : startingPoint;
        const scale = backwarts
          ? startingPoint - left + startingElementWidth
          : left - startingPoint + width;

        const afterFirstTransition = () => {
          $indicator.style.transform = `translateX(${left}px) scaleX(${width})`;
          $indicator.removeEventListener('transitionend', afterFirstTransition);
        };

        $indicator.addEventListener('transitionend', afterFirstTransition);
        $indicator.style.opacity = '1';
        $indicator.style.transform = `translateX(${translate}px) scaleX(${scale})`;
      } else {
        // Start from zero
        $indicator.style.opacity = '1';
        $indicator.style.transform = `translateX(${left}px) scaleX(${width})`;
      }

      // Update current active item
      $elementWithIndicator = $trigger;
    } else {
      $indicator.style.opacity = '0';
      $indicator.style.transform = 'translateX(0) scaleX(0)';
      $elementWithIndicator = null;
    }
  };

  // Show active indicator on load
  const $active = $navigation.querySelector<HTMLAnchorElement>(
    '.header__navigation-1st-level-link--active',
  );

  if ($active && $indicator) {
    moveIndicator($active);
  }

  // On link click
  const on1stLevelClick = (event: MouseEvent) => {
    const $trigger =
      event.target instanceof Element
        ? event.target.closest<HTMLLinkElement>(
            '.header__navigation-1st-level-link',
          )
        : null;

    const $submenu =
      $trigger?.parentNode?.querySelector<HTMLUListElement>('ul');

    if (!$trigger || !$submenu) {
      return;
    }

    // Prevent default link handler
    event.preventDefault();

    // Close menu again
    if (!$submenu.hidden) {
      focusTrap?.deactivate();
      return;
    }

    // Kill other focus traps first
    focusTrap?.deactivate();

    // Make new submenu visible
    $submenu.hidden = false;
    $currentSubmenuTrigger = $trigger;

    // Create focus trap
    focusTrap = createFocusTrap($submenu, {
      escapeDeactivates: true,
      allowOutsideClick: true,
      initialFocus: false,
      returnFocusOnDeactivate: false,
      clickOutsideDeactivates(outsideClickEvent) {
        return !(
          outsideClickEvent.target instanceof Element &&
          outsideClickEvent.target?.closest(
            '.header__navigation-1st-level-link',
          )
        );
      },
      onActivate() {
        // Set aria attribute
        $trigger.setAttribute('aria-expanded', 'true');

        // Focus on first link
        moveFocus($submenu);

        // Move indicator
        moveIndicator(
          $trigger,
          $currentSubmenuTrigger || $elementWithIndicator,
        );
      },
      onDeactivate() {
        // Set aria attribute
        $trigger.setAttribute('aria-expanded', 'false');

        // Hides menu
        $submenu.hidden = true;
        $currentSubmenuTrigger = null;

        // Move focus back to the trigger
        moveFocus($trigger);

        // Move indicator
        moveIndicator($active, $trigger);
      },
    });

    // Enables focus trap and show menu
    focusTrap.activate();
  };

  // Indicator
  const onMouseover = (event: MouseEvent) => {
    const $trigger =
      event.target instanceof Element
        ? event.target.closest<HTMLLinkElement>(
            '.header__navigation-1st-level-link',
          )
        : null;

    if (!$trigger || !$indicator) {
      return;
    }

    moveIndicator($trigger, $elementWithIndicator);
  };

  const onMouseleave = () => {
    if (!$indicator) {
      return;
    }

    moveIndicator($currentSubmenuTrigger || $active, $elementWithIndicator);
  };

  // Modify DOM
  $navigation
    .querySelectorAll<HTMLUListElement>('.header__navigation-2nd-level')
    .forEach(($submenu) => {
      // Add aria-expanded & aria-controls
      const $trigger =
        $submenu?.parentElement?.querySelector<HTMLAnchorElement>(
          '.header__navigation-1st-level-link',
        );

      $trigger?.setAttribute('aria-expanded', 'false');
      $trigger?.setAttribute('aria-controls', $submenu.id);
    });

  $navigation
    .querySelectorAll<HTMLUListElement>('.header__navigation-3rd-level')
    .forEach(($submenu) => {
      $submenu.hidden = false;
    });

  // Init events
  $navigation.addEventListener('click', on1stLevelClick);
  $navigation.addEventListener('mouseover', onMouseover);
  $navigation.addEventListener('mouseleave', onMouseleave);

  // De-init
  return () => {
    // Remove events
    $navigation.removeEventListener('click', on1stLevelClick);
    $navigation.removeEventListener('mouseover', onMouseover);
    $navigation.removeEventListener('mouseleave', onMouseleave);

    // Restore DOM
    $navigation
      .querySelectorAll<HTMLUListElement>('.header__navigation-2nd-level')
      .forEach(($submenu) => {
        // Remove aria-expanded & aria-controls
        const $trigger =
          $submenu?.parentElement?.querySelector<HTMLAnchorElement>(
            '.header__navigation-1st-level-link',
          );

        $trigger?.removeAttribute('aria-expanded');
        $trigger?.removeAttribute('aria-controls');

        // Hide all submenus
        $submenu.hidden = true;
      });

    $navigation
      .querySelectorAll<HTMLUListElement>('.header__navigation-3rd-level')
      .forEach(($submenu) => {
        $submenu.hidden = true;
      });
  };
};

export default createDesktopNavigation;
