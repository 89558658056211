import { on } from 'delegated-events';

on('change', 'form.job-search-bar', async (event) => {
  const { currentTarget: $container } = event;
  const { counterEndpoint = null } = $container.dataset;

  if (!counterEndpoint) {
    return;
  }

  const $buttonContainer = $container.querySelector<HTMLDivElement>(
    '.job-search-bar__button',
  );
  const $button =
    $buttonContainer?.querySelector<HTMLButtonElement>('button.button');

  if (!$button) {
    return;
  }

  const showLoading = setTimeout(() => {
    $buttonContainer?.classList.add('is-requesting');
  }, 250);

  const formData = new FormData($container);
  const response = await fetch(counterEndpoint, {
    method: 'POST',
    body: formData,
    cache: 'no-cache',
    mode: 'cors',
  });

  if (response.status === 200) {
    const { buttonText } = await response.json();
    $button.textContent = buttonText;
    clearTimeout(showLoading);
    $buttonContainer?.classList.remove('is-requesting');
  }
});
