import createSlider from '../../../javascripts/utils/createSlider';
import invisibleFocus from '../../../javascripts/utils/invisibleFocus';

const $$gallery = document.querySelectorAll<HTMLDivElement>('.gallery');

$$gallery.forEach(($gallery) => {
  const $slides = $gallery.querySelector<HTMLDivElement>('.gallery__images');

  if (!$slides) {
    return;
  }

  const slider = createSlider($slides, '.gallery__image');

  $gallery
    .querySelector<HTMLButtonElement>('.gallery__navigation-button--prev')
    ?.addEventListener('click', ({ currentTarget }) => {
      if (currentTarget instanceof HTMLElement) invisibleFocus(currentTarget);
      slider.move('prev');
    });

  $gallery
    .querySelector('.gallery__navigation-button--next')
    ?.addEventListener('click', ({ currentTarget }) => {
      if (currentTarget instanceof HTMLElement) invisibleFocus(currentTarget);
      slider.move('next');
    });
});
