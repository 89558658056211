import { on } from 'delegated-events';

on('click', '.html-embed__button', (event) => {
  // Check if necessary DOM structure exists
  const $htmlEmbed = event.currentTarget.closest<HTMLDivElement>('.html-embed');
  const $container = $htmlEmbed?.querySelector<HTMLDivElement>(
    '.html-embed__container',
  );
  const $embed =
    $htmlEmbed?.querySelector<HTMLTemplateElement>('.html-embed__embed');

  if (!$htmlEmbed || !$container || !$embed) {
    return;
  }

  // Disable button click
  event.preventDefault();

  // Clean up container element
  while ($container.lastChild) {
    $container.removeChild($container.lastChild);
  }

  // Attach player to container
  $container.appendChild($embed.content.cloneNode(true));
  $htmlEmbed.classList.add('html-embed--enabled');
});
