class PyramidGraphic {
    constructor($el) {
        this.$el = $el;
        this.$pyramid = this.$el.querySelector('.pyramid-graphic__pyramid');
        this.$pyramidSections = this.$el.querySelectorAll('.pyramid-graphic__pyramid-section');
        this.$pyramidAnnotations = this.$el.querySelectorAll('.pyramid-graphic__transition-annotation:not(.pyramid-graphic__transition-annotation--empty)');
        this.$pyramidSideTextClip = this.$el.querySelector('.pyramid-graphic__side-text-clip');

        this.id = this.$el.id;
        this.mobileBp = 1023;
        this.resizeDelay = 200;
        this.resizeInterval = undefined;

        this.InitEvents();
        this.CalculateAnnotations();
        window.dispatchEvent(new Event('resize')); // double execution by trigger event to prevent initial calculation error
        this.$el.classList.add('binded');
    }

    CalculateAnnotations() {
        var self = this;
        var pyramidHeight = this.$pyramid.offsetHeight;
        var pyramidHalfWidth = this.$pyramid.offsetWidth/2;
        var pyramidBottomLine = document.querySelector('.pyramid-graphic__pyramid-bottom-line');
        pyramidBottomLine.classList.remove('pyramid-graphic__pyramid-bottom-line--hidden');
        pyramidBottomLine.style.top = pyramidHeight - 6 + 'px';
        if(this.$pyramidSideTextClip) {
            this.$pyramidSideTextClip.style.height = pyramidHeight + 'px';
        }
        this.$pyramidSections.forEach((section,index)=>{
            var annotation = document.getElementById(self.id + '__annotation-' + section.dataset.count);
            if(annotation) {
                if(window.innerWidth > self.mobileBp) {
                    var bottomBorder = section.offsetTop + section.offsetHeight;
                    var minRightDistance = ((bottomBorder / pyramidHeight) * pyramidHalfWidth) + 10;
                    self.$pyramid.removeAttribute('style');
                    annotation.style.top = bottomBorder - (annotation.offsetHeight / 2) - 5 + 'px';
                    annotation.style.paddingRight = minRightDistance + 50 + 'px';
                    annotation.querySelector('.pyramid-graphic__transition-annotation__arrow').style.right = minRightDistance + 'px';
                    annotation.querySelector('.pyramid-graphic__transition-annotation__line').style.right = minRightDistance + 'px';
                    if (index + 1 == self.$pyramidSections.length ) {
                        self.$pyramid.style.marginBottom = annotation.offsetHeight + 'px';
                        pyramidBottomLine.classList.add('pyramid-graphic__pyramid-bottom-line--hidden');
                    }
                } else {
                    annotation.removeAttribute('style');
                }
            }
        });
    }

    InitEvents() {
        var self = this;
        window.addEventListener('resize',()=>{
            window.clearInterval(self.resizeInterval);
            self.resizeInterval = window.setInterval(()=>{
                window.clearInterval(self.resizeInterval);
                self.CalculateAnnotations();
            },self.resizeDelay);
        });
    }
}

document.querySelectorAll('.pyramid-graphic:not(.binded)').forEach((el) => {
    new PyramidGraphic(el);
});