import { on } from 'delegated-events';

on('click', '.video__play', (event) => {
  // Check if necessary DOM structure exists
  const $video = event.currentTarget.closest<HTMLDivElement>('.video');
  const $container = $video?.querySelector<HTMLDivElement>('.video__container');
  const $player = $video?.querySelector<HTMLTemplateElement>('.video__player');

  if (!$video || !$container || !$player) {
    return;
  }

  // Disable button click
  event.preventDefault();

  // Clean up container element
  while ($container.lastChild) {
    $container.removeChild($container.lastChild);
  }

  // Attach player to container
  $container.appendChild($player.content.cloneNode(true));

  // Apply class to iFrame
  $container
    .querySelector<HTMLIFrameElement>('iframe')
    ?.classList.add('video__iframe');
});
