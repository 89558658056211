import createDesktopNavigation from './header.desktop';
import createMobileNavigation from './header.mobile';

document
  .querySelectorAll<HTMLDivElement>('.header__navigation-levels')
  .forEach(($navigation) => {
    // Current deinit function
    let deinit: CallableFunction | undefined;

    // Init media query
    const mql = window.matchMedia('(min-width: 768px)');

    // Listener
    const onMediaQueryChange = (mq: MediaQueryListEvent | MediaQueryList) => {
      const isDesktop = mq.matches;

      deinit?.();

      if (isDesktop) {
        deinit = createDesktopNavigation($navigation);
      } else {
        deinit = createMobileNavigation($navigation);
      }
    };

    // Listen on media query changes
    mql.addEventListener('change', onMediaQueryChange);

    // First run
    onMediaQueryChange(mql);
  });
