import { createFocusTrap, FocusTrap } from 'focus-trap';
import { on } from 'delegated-events';
import { Instance } from '@popperjs/core';
import invisibleFocus from '../../../javascripts/utils/invisibleFocus';
import createPopper from '../../../javascripts/utils/createPopper';

let focusTrap: FocusTrap;
let popper: Instance;

on('click', 'button.copyright__button', (event) => {
  const { currentTarget: $button } = event;
  const $parent = $button.closest<HTMLDivElement>('.copyright');
  const $tooltip = $parent?.querySelector<HTMLDivElement>(
    '.copyright__tooltip',
  );

  if ($parent && $tooltip) {
    event.preventDefault();

    if (!$tooltip.hidden) {
      focusTrap?.deactivate();
    } else {
      $tooltip.hidden = false;

      popper = createPopper($button, $tooltip, {
        placement: 'top-end',
        strategy: 'fixed',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1],
            },
          },
        ],
      });

      focusTrap = createFocusTrap($parent, {
        onDeactivate() {
          $tooltip.hidden = true;
          popper?.destroy();
          invisibleFocus($button);
        },
        initialFocus: '.copyright__tooltip',
        clickOutsideDeactivates: true,
        returnFocusOnDeactivate: false,
        preventScroll: false,
      });

      focusTrap.activate();
    }
  }
});
