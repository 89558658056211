var PanoramaOverlay = require('../panorama-overlay/panorama-overlay');
var Quaternion = require('../../../javascripts/utils/quaternion');

class PanoramaViewer {
    constructor($el) {
        this.$el = $el;
        this.$build = false;
        this.$viewer = undefined;

        this.$loaderButton = this.$el.querySelector('.panorama-viewer__loader .button');
        this.$toStartButton = this.$el.querySelector('.panorama-viewer--to-start');
        this.$toStartMobileButton = this.$el.querySelector('.panorama-viewer--to-start-mobile');
        this.$stopButton = this.$el.querySelector('.panorama-viewer--stop');
        this.$vrButton = undefined;
        this.$resizeDelay = undefined;
        this.$isTouch = false;
        this.$startUpOverlayDesktop = false;
        this.$startUpOverlayMobile = false;
        this.$startUpOverlayRepetition = false;
        this.$startUpOverlayShown = false;
        this.$startUpOverlayVr = false;
        this.$footprint = 'pv_' + this.HashSettings();

        this.$vrActive = false;
        this.$vrStartUpOverlay = undefined;
        this.$vrStartUpOverlayActive = false;
        this.$vrViewerLeft = undefined;
        this.$vrViewerLeftLoaded = false;
        this.$vrViewerRight = undefined;
        this.$vrViewerRightLoaded = false;
        this.$vrViewerPointer = undefined;
        this.$vrViewerTick = 40;
        this.$vrViewerUpdater = undefined;
        this.$vrViewerHotSpotLeft = undefined;
        this.$vrViewerHotSpotRight = undefined;
        this.$vrViewerHotSpotCounter = 0;
        this.$vrViewerHotSpotTriggered = new Date();
        this.$vrViewerHotSpotTriggerDelay = 2000;
        this.$vrViewerPitchBound = 20;
        this.$vrViewerYawBound = 10;
        this.$vrViewerOverlayOpen = false;
        this.$vrViewerOverlayLeft = undefined;
        this.$vrViewerOverlayRight = undefined;
        this.$vrViewerOverlayPointers = undefined;
        this.$vrViewerOverlayPointersSensitivityX = 3;
        this.$vrViewerOverlayPointersSensitivityY = 1.5;
        this.$vrViewerOverlayButtonLeft = undefined;
        this.$vrViewerOverlayButtonRight = undefined;
        this.$vrViewerOverlayButtonCounter = 0;

        this.InitEvents();
        this.$el.classList.add('binded');
    }

    // Hash Settings
    HashSettings() {
        return Math.abs(JSON.stringify(window[this.$el.id]['scenes']).split('').reduce((prevHash, currVal) =>
            (((prevHash << 5) - prevHash) + currVal.charCodeAt(0)) | 0, 0));
    }

    // Initiate Button Events
    InitEvents() {

        // start/load button
        this.$loaderButton.addEventListener('click', (e) => {
            e.preventDefault();

            this.$el.classList.add('active');
            if (!this.$build) {
                this.BuildViewer();
            }

            this.$el.scrollIntoView();

        });
        // touch
        this.$loaderButton.addEventListener('touchend', (e) => {
            this.$isTouch = true;
        });

        // to start button
        if (this.$toStartButton) {
            this.$toStartButton.addEventListener('click', (e) => {
                e.preventDefault();
                this.ToStart();
                this.$toStartButton.blur();
            });
        }
        if (this.$toStartMobileButton) {
            this.$toStartMobileButton.addEventListener('click', (e) => {
                e.preventDefault();
                this.ToStart();
                this.$toStartMobileButton.blur();
            });
        }

        // stop/leave button
        this.$stopButton.addEventListener('click', (e) => {
            e.preventDefault();
            this.$el.classList.remove('active');
            this.StopVrViewer();
        });

    }

    // Build Panellum Panorama
    BuildViewer() {
        this.$viewer = pannellum.viewer(this.$el.id + '__panorama', window[this.$el.id]);
        this.$build = true;
        this.$viewer.on("scenechange", () => {
            this.SetHfovByOrientation();
        });
        this.SetHfovByOrientation();
        this.BindWindowOrientationChange();
        if (typeof window[this.$el.id]['default']['panoramaId'] !== "undefined") {
            this.$footprint = 'pv_' + window[this.$el.id]['default']['panoramaId'];
        }
        this.StartUpOverlay();

        this.BindVrViewer();

    }

    // Destroy Panellum Main Viewer
    DestroyViewer() {
        this.$viewer.destroy();
        this.$build = false;
        this.$vrButton = undefined;
    }

    // Start Up Overlay
    StartUpOverlay() {
        // get settings
        this.$startUpOverlayDesktop = window[this.$el.id]['default']['startUpOverlayDesktop'] || false;
        this.$startUpOverlayMobile = window[this.$el.id]['default']['startUpOverlayMobile'] || false;
        this.$startUpOverlayRepetition = window[this.$el.id]['default']['startUpOverlayRepetition'] || false;
        this.$startUpOverlayShown = localStorage.getItem(this.$footprint + '_suo-shown') || false;
        // check if old storage item can be deleted
        if (!this.$startUpOverlayRepetition && this.$startUpOverlayShown) {
            localStorage.removeItem(this.$footprint + '_suo-shown');
        }
        // check for automatic start up overlay
        if ((this.$startUpOverlayRepetition && !this.$startUpOverlayShown) || !this.$startUpOverlayRepetition) {
            if ((this.$startUpOverlayDesktop || this.$startUpOverlayMobile) && !this.$isTouch) {
                this.OpenOverlay(this.$startUpOverlayDesktop || this.$startUpOverlayMobile);
                if (this.$startUpOverlayRepetition) {
                    localStorage.setItem(this.$footprint + '_suo-shown', true);
                }
            }
            if ((this.$startUpOverlayMobile || this.$startUpOverlayDesktop) && this.$isTouch) {
                this.OpenOverlay(this.$startUpOverlayMobile || this.$startUpOverlayDesktop);
                if (this.$startUpOverlayRepetition) {
                    localStorage.setItem(this.$footprint + '_suo-shown', true);
                }
            }
        }
    }

    // Open Overlay
    OpenOverlay(id) {
        var overlayRequest = new XMLHttpRequest();
        var self = this;
        overlayRequest.onreadystatechange = function () {
            if (overlayRequest.readyState == 4) {
                if (overlayRequest.status == 200) {
                    if (overlayRequest.responseText && overlayRequest.responseText != "") {
                        var parser = new DOMParser();
                        var doc = parser.parseFromString(overlayRequest.responseText, 'text/html');
                        var childNodes = doc.body.childNodes;

                        if (self.$vrActive && !this.$vrStartUpOverlayActive) {
                            var overlayElemLeft = childNodes.length == 1 ? childNodes[0] : childNodes;
                            var overlayElemRight = overlayElemLeft.cloneNode(true);
                            overlayElemLeft.classList.add('panorama-overlay--vr', 'panorama-overlay--vr-left');
                            overlayElemRight.classList.add('panorama-overlay--vr', 'panorama-overlay--vr-right');
                            document.getElementById(self.$el.id + '__panorama-vr').querySelector('.panorama-viewer__panorama-vr__overlays').appendChild(overlayElemLeft);
                            self.$vrViewerOverlayLeft = new PanoramaOverlay(overlayElemLeft);
                            document.getElementById(self.$el.id + '__panorama-vr').querySelector('.panorama-viewer__panorama-vr__overlays').appendChild(overlayElemRight);
                            self.$vrViewerOverlayRight = new PanoramaOverlay(overlayElemRight);
                            self.VrViewerOverlaysInitEvents();
                            //self.VrViewerOverlayControl();
                        } else {
                            var overlayElem = childNodes.length == 1 ? childNodes[0] : childNodes;
                            self.$el.appendChild(overlayElem);
                            if(self.$vrStartUpOverlayActive) {
                                self.$vrStartUpOverlay = new PanoramaOverlay(overlayElem);
                                self.$vrStartUpOverlay.$el.addEventListener('overlay-closed',(e)=>{
                                    self.$vrStartUpOverlayActive = false;
                                    self.StartVrViewer();
                                });
                            } else {
                                new PanoramaOverlay(overlayElem);
                            }
                        }
                    }
                }
            }
        }
        overlayRequest.open("GET", window.location.protocol + "//" + window.location.host + "/api/panorama/overlay/" + id, true);
        overlayRequest.send();
    }

    // Bind Window Orientation Change to reset HFOV
    BindWindowOrientationChange() {
        window.addEventListener('resize', (e) => {
            window.clearInterval(this.$resizeDelay);
            this.$resizeDelay = window.setInterval(() => {
                window.clearInterval(this.$resizeDelay);
                if (this.$vrActive) {
                    this.CheckVrScreenOrientation();
                } else {
                    this.SetHfovByOrientation();
                }
            }, 250);
        });

    }

    // Get HFOV from Scene
    GetHfovFromScene(scene) {
        // PORTRAIT
        if (window.innerHeight > window.innerWidth) {
            return window[this.$el.id].scenes[scene].hfov / 2;
            // LANDSCAPE
        } else {
            return window[this.$el.id].scenes[scene].hfov;
        }
    }

    // Set HFOV by Window Orientation
    SetHfovByOrientation() {
        if (typeof this.$viewer.getScene() !== "undefined") {
            this.$viewer.setHfov(this.GetHfovFromScene(this.$viewer.getScene()));
        }
    }

    // To Start
    ToStart() {
        let firstScene = Object.keys(window[this.$el.id].scenes)[0];
        let firstSceneData = window[this.$el.id].scenes[firstScene];
        if (this.$viewer.getScene() !== firstScene) {
            this.$viewer.loadScene(
                firstScene,
                firstSceneData.pitch,
                firstSceneData.yaw,
                firstSceneData.hfov
            );
        } else {
            this.$viewer.lookAt(
                firstSceneData.pitch,
                firstSceneData.yaw,
                this.GetHfovFromScene(firstScene),
            );
        }
    }

    // Bind VR
    BindVrViewer() {
        if (this.$el.classList.contains('panorama-viewer--vr') && this.$viewer.isOrientationSupported()) {
            if (typeof this.$vrButton === "undefined") {
                this.$vrButton = document.createElement('div');
                this.$vrButton.classList.add('pnlm-sprite', 'pnlm-controls', 'pnlm-control', 'pnlm-vr');
                this.$vrViewerOverlayPointers = this.$el.querySelector('.panorama-viewer__panorama-vr__overlay-pointers');
                this.$el.querySelector('.panorama-viewer__panorama .pnlm-controls-container').appendChild(this.$vrButton);
                this.$vrButton.addEventListener('click', (e) => {
                    this.StartUpOverlayVr();
                    if(!this.$vrStartUpOverlayActive) {
                        this.StartVrViewer();
                    }
                });
                this.$vrViewerPointer = this.$el.querySelector('.panorama-viewer__panorama-vr__pointer');
            }
        }
    }

    // Start Up Overlay VR
    StartUpOverlayVr() {
        this.$startUpOverlayVr = window[this.$el.id]['default']['startUpOverlayVr'] || false;
        if(this.$startUpOverlayVr) {
            this.$vrStartUpOverlayActive = true;
            this.OpenOverlay(this.$startUpOverlayVr);
        }
    }

    // Start VR Viewer
    StartVrViewer() {
        this.DestroyViewer();
        var self = this;
        if (typeof DeviceMotionEvent.requestPermission === 'function') {
            DeviceOrientationEvent.requestPermission().then(function(response) {
                if (response == 'granted') {
                    self.LoadVrViewers();
                    self.VrOrientationListener();
                }
            });
        } else {
            this.LoadVrViewers();
            this.VrOrientationListener();
        }
    }

    // Load VR Viewers
    LoadVrViewers(){
        this.$vrViewerLeft = pannellum.viewer(this.$el.id + '__panorama-vr__left', window[this.$el.id]);
        this.$vrViewerLeft.on('load', () => {
            this.$vrViewerLeftLoaded = true;
            this.VrSceneLoaded();
        });
        this.$vrViewerLeft.on('scenechange', () => {
            this.$vrViewerLeft.setHfov(this.GetHfovFromScene(this.$vrViewerLeft.getScene()));
        });
        window[this.$el.id + '_2'] = JSON.parse(JSON.stringify(window[this.$el.id]));
        this.$vrViewerRight = pannellum.viewer(this.$el.id + '__panorama-vr__right', window[this.$el.id + '_2']);
        this.$vrViewerRight.on('load', () => {
            this.$vrViewerRightLoaded = true;
            this.VrSceneLoaded();
        });
        this.$vrViewerRight.on('scenechange', () => {
            this.$vrViewerRight.setHfov(this.GetHfovFromScene(this.$vrViewerRight.getScene()));
        });
        this.$el.classList.add('panorama-viewer--vr-active');
        this.$vrActive = true;

        this.CheckVrScreenOrientation();
    }

    // VR Orientation Listener
    VrOrientationListener() {
        var orientation = 1;
        window.addEventListener('deviceorientation', (e) => {
            // skip first 10 events, due to a iOS problem
            if(typeof(orientation) == 'number' && orientation < 10 ) {
                orientation++;
            } else {
                orientation = true;
                let q = this.ComputeQuaternion(e.alpha, e.beta, e.gamma).ToEulerAngles();
                
                let pitch = q[0] / Math.PI * 180;
                let yaw = -q[2] / Math.PI * 180;

                if(this.$vrViewerOverlayOpen) {
                    let x = yaw;
                    let y = pitch;

                    y = y > 90 ? y - 180 : (y < -90 ? y + 180 : -y);

                    let transformX = ((x / 180) * 50 * this.$vrViewerOverlayPointersSensitivityX);
                    let transformY = ((y / 90) * 100 * this.$vrViewerOverlayPointersSensitivityY);

                    transformX = transformX < 0 ? 50 + transformX % 50 : (transformX > 50 ? transformX % 50 : transformX);
                    transformY = transformY < 0 ? 100 + transformY % 100 : (transformY > 100 ? transformY % 100 : transformY);

                    this.$vrViewerOverlayPointers.querySelectorAll('.panorama-viewer__panorama-vr__overlay-pointer').forEach(pointerElem => {
                        pointerElem.style.marginTop = `${transformY}vh`;
                        pointerElem.style.marginLeft = `${transformX}vw`;
                    });
                }
            }
        }, true);
    }

    // VR Scene Loaded
    VrSceneLoaded() {
        if (this.$vrViewerLeftLoaded && this.$vrViewerRightLoaded) {
            this.$vrViewerLeft.startOrientation();
            this.$vrViewerRight.startOrientation();
            this.$vrViewerHotSpotTriggered = new Date();
            this.StartVrViewerUpdater();
        }
    }

    // Stop VR Viewer
    StopVrViewer() {
        this.$el.classList.remove('panorama-viewer--vr-active', 'panorama-viewer--vr-working', 'panorama-viewer--vr-pointer-active', 'panorama-viewer--vr-overlay-open');
        this.$vrActive = false;
        this.$vrViewerLeft.destroy();
        this.$vrViewerRight.destroy();
        this.StopVrViewerUpdater();
    }

    // Check VR Screen Orientation
    CheckVrScreenOrientation() {
        if (window.innerHeight < window.innerWidth) {
            this.$el.classList.add('panorama-viewer--vr-working');
        } else {
            this.$el.classList.remove('panorama-viewer--vr-working');
        }
    }

    // Set VR Viewer Update
    VrViewerUpdate() {
        if (!this.$vrViewerOverlayOpen) {
            if(new Date().getTime() - this.$vrViewerHotSpotTriggered.getTime() >= this.$vrViewerHotSpotTriggerDelay) {
                let pointerBounding = this.$vrViewerPointer.getBoundingClientRect();
                let hotSpotFound = false;
                this.$el.classList.remove('panorama-viewer--vr-pointer-active');
                
                // HOT SPOT DETECTION
                window[this.$el.id]['scenes'][this.$vrViewerLeft.getScene()]['hotSpots'].forEach((hotSpot, index) => {
                    let hotSpotBounding = hotSpot.div.getBoundingClientRect();
                    let hotSpotCenter = {x: hotSpotBounding.x + (hotSpotBounding.width / 2), y: hotSpotBounding.y + (hotSpotBounding.height / 2)};
                    if (
                        hotSpotCenter.x >= pointerBounding.x && hotSpotCenter.x <= (pointerBounding.x + pointerBounding.width) &&
                        hotSpotCenter.y >= pointerBounding.y && hotSpotCenter.y <= (pointerBounding.y + pointerBounding.height)    
                    ) {
                        hotSpotFound = true;
                        this.$el.classList.add('panorama-viewer--vr-pointer-active');
                        if (this.$vrViewerHotSpotLeft != hotSpot) {
                            this.$vrViewerHotSpotLeft = hotSpot;
                            this.$vrViewerHotSpotRight = window[this.$el.id + '_2']['scenes'][this.$vrViewerRight.getScene()]['hotSpots'][index];
                            this.$vrViewerHotSpotLeft.div.classList.add('show-tooltip');
                            this.$vrViewerHotSpotRight.div.classList.add('show-tooltip');
                        } else {
                            this.$vrViewerHotSpotCounter++;
                            if (this.$vrViewerHotSpotCounter >= ((1000 / this.$vrViewerTick) * 2)) {
                                this.$vrViewerHotSpotLeft.div.style.removeProperty('font-size');
                                this.$vrViewerHotSpotRight.div.style.removeProperty('font-size');
                                this.$vrViewerHotSpotLeft.div.classList.remove('show-tooltip');
                                this.$vrViewerHotSpotRight.div.classList.remove('show-tooltip');
                                this.VrHotSpotHandler();
                                this.$el.classList.remove('panorama-viewer--vr-pointer-active');
                                this.$vrViewerHotSpotCounter = 0;
                            } else {
                                var fontSize = parseInt(window.getComputedStyle(this.$vrViewerHotSpotLeft.div, null).getPropertyValue('font-size'), 10) + (this.$vrViewerHotSpotCounter * (this.$vrViewerTick) * 0.005) + 'px';
                                this.$vrViewerHotSpotLeft.div.style.setProperty('font-size', fontSize);
                                this.$vrViewerHotSpotRight.div.style.setProperty('font-size', fontSize);
                            }
                        }
                    }
                });
                if (!hotSpotFound) {
                    this.$vrViewerHotSpotCounter = 0;
                    if (typeof this.$vrViewerHotSpotLeft !== "undefined") {
                        this.$vrViewerHotSpotLeft.div.style.removeProperty('font-size');
                        this.$vrViewerHotSpotRight.div.style.removeProperty('font-size');
                        this.$vrViewerHotSpotLeft.div.classList.remove('show-tooltip');
                        this.$vrViewerHotSpotRight.div.classList.remove('show-tooltip');
                        this.$vrViewerHotSpotLeft = undefined;
                        this.$vrViewerHotSpotRight = undefined;
                    }
                }
            }
        } else {
            // OVERLAY BUTTON DETECTION
            if(typeof this.$vrViewerOverlayLeft !== "undefined" && typeof this.$vrViewerOverlayLeft.$el !== "undefined") {
                let pointerLeftBounding = this.$vrViewerOverlayPointers.querySelector('.panorama-viewer__panorama-vr__overlay-pointers__left .panorama-viewer__panorama-vr__overlay-pointer').getBoundingClientRect();
                let buttonFound = false;
                let index = 0;
                Array.from(this.$vrViewerOverlayLeft.$el.querySelectorAll('button')).every(button => {
                    if(!button.disabled) {
                        let buttonBounding = button.getBoundingClientRect();
                        let buttonCenter = {x: buttonBounding.x + (buttonBounding.width / 2), y: buttonBounding.y + (buttonBounding.height / 2)};
                        if (
                            buttonCenter.x >= pointerLeftBounding.x && buttonCenter.x <= (pointerLeftBounding.x + pointerLeftBounding.width) &&
                            buttonCenter.y >= pointerLeftBounding.y && buttonCenter.y <= (pointerLeftBounding.y + pointerLeftBounding.height)    
                        ) {
                            buttonFound = true;
                            this.$el.classList.add('panorama-viewer--vr-overlay-pointer-active');
                            if (this.$vrViewerOverlayButtonLeft != button) {
                                this.$vrViewerOverlayButtonLeft = button;
                                this.$vrViewerOverlayButtonRight = Array.from(this.$vrViewerOverlayRight.$el.querySelectorAll('button'))[index];
                            } else {
                                this.$vrViewerOverlayButtonCounter++;
                                if (this.$vrViewerOverlayButtonCounter >= ((1000 / this.$vrViewerTick) * 1.5)) {
                                    this.$vrViewerOverlayButtonLeft.style.removeProperty('transform');
                                    this.$vrViewerOverlayButtonRight.style.removeProperty('transform');
                                    this.$vrViewerOverlayButtonLeft.click();
                                    this.$vrViewerOverlayButtonRight.click();
                                    this.$vrViewerOverlayButtonLeft = undefined;
                                    this.$vrViewerOverlayButtonRight = undefined;
                                    this.$vrViewerOverlayButtonCounter = 0;
                                    this.$el.classList.remove('panorama-viewer--vr-overlay-pointer-active');
                                    return false;
                                } else {
                                    var scale = this.$vrViewerOverlayButtonCounter / 15;
                                    scale = scale < 1 ? 1 : scale;
                                    var existingStyle = window.getComputedStyle(this.$vrViewerOverlayButtonLeft);
                                    var transformMatrix = existingStyle.transform || existingStyle.webkitTransform || existingStyle.mozTransform;
                                    var matrixValues = transformMatrix.match(/matrix.*\((.+)\)/);
                                    if(matrixValues) {
                                        this.$vrViewerOverlayButtonLeft.style.setProperty('transform', `translateX(${matrixValues[1].split(', ')[4]}) translateY(${matrixValues[1].split(', ')[5]}) scale(${scale})`);
                                        this.$vrViewerOverlayButtonRight.style.setProperty('transform', `translateX(${matrixValues[1].split(', ')[4]}) translateY(${matrixValues[1].split(', ')[5]}) scale(${scale})`);
                                    } else {
                                        this.$vrViewerOverlayButtonLeft.style.setProperty('transform', `scale(${scale})`);
                                        this.$vrViewerOverlayButtonRight.style.setProperty('transform', `scale(${scale})`);
                                    }
                                }
                            }
                            return false;
                        }
                    }
                    index++;
                    return true;
                });
                if (!buttonFound) {
                    this.$vrViewerOverlayButtonCounter = 0;
                    if (typeof this.$vrViewerOverlayButtonLeft !== "undefined") {
                        this.$vrViewerOverlayButtonLeft.style.removeProperty('transform');
                        this.$vrViewerOverlayButtonRight.style.removeProperty('transform');
                        this.$vrViewerOverlayButtonLeft = undefined;
                        this.$vrViewerOverlayButtonRight = undefined;
                        this.$el.classList.remove('panorama-viewer--vr-overlay-pointer-active');
                    }
                }
            }
        }
    }
    StartVrViewerUpdater() {
        this.$vrViewerUpdater = window.setInterval(() => { this.VrViewerUpdate() }, this.$vrViewerTick);
    }
    StopVrViewerUpdater() {
        window.clearInterval(this.$vrViewerUpdater);
    }

    // VR Overlay Opener
    VrHotSpotHandler() {
        if (this.$vrViewerHotSpotLeft.type == "scene") {
            this.StopVrViewerUpdater();
            this.$vrViewerLeftLoaded = false;
            this.$vrViewerRightLoaded = false;
            this.$vrViewerHotSpotLeft.div.click();
            this.$vrViewerHotSpotRight.div.click();
        } else {
            this.$vrViewerHotSpotLeft.div.click();
            this.$vrViewerOverlayOpen = true;
            this.$vrViewerLeft.stopOrientation();
            this.$vrViewerRight.stopOrientation();
            this.$el.classList.add('panorama-viewer--vr-overlay-open');
        }
        this.$vrViewerHotSpotTriggered = new Date();
        this.$vrViewerHotSpotLeft = undefined;
        this.$vrViewerHotSpotRight = undefined;
    }

    // VR Viewer Overlays Events
    VrViewerOverlaysInitEvents() {
        if(typeof this.$vrViewerOverlayLeft.$el !== "undefined") {
            this.$vrViewerOverlayLeft.$el.addEventListener('overlay-closed',(e)=>{
                if(typeof this.$vrViewerOverlayRight.$el !== "undefined") {
                    this.$vrViewerOverlayRight.ClosePanoramaOverlay();
                }
                this.VrOverlayClosed();
            }, false);
        }
        if(typeof this.$vrViewerOverlayRight.$el !== "undefined") {
            this.$vrViewerOverlayRight.$el.addEventListener('overlay-closed',(e)=>{
                if(typeof this.$vrViewerOverlayLeft.$el !== "undefined") {
                    this.$vrViewerOverlayLeft.ClosePanoramaOverlay();
                }
                this.VrOverlayClosed();
            }, false);
        }
    }

    CloseVrOverlays() {
        this.$vrViewerOverlayLeft.ClosePanoramaOverlay();
        this.$vrViewerOverlayRight.ClosePanoramaOverlay();
        this.$vrViewerOverlayPointers.querySelectorAll('.panorama-viewer__panorama-vr__overlay-pointer').forEach(pointerElem => {
            pointerElem.style.removeProperty('margin-top');
            pointerElem.style.removeProperty('margin-left');
        });
        this.$vrViewerHotSpotTriggered = new Date();
    }
    VrOverlayClosed() {
        this.$vrViewerOverlayOpen = false;
        this.$el.classList.remove('panorama-viewer--vr-overlay-open');
        this.$vrViewerLeft.startOrientation();
        this.$vrViewerRight.startOrientation();
    }

    // Converts device orientation API Tait-Bryan angles to a quaternion
    TaitBryanToQuaternion(alpha, beta, gamma) {
        var r = [beta ? beta * Math.PI / 180 / 2 : 0,
                 gamma ? gamma * Math.PI / 180 / 2 : 0,
                 alpha ? alpha * Math.PI / 180 / 2 : 0];
        var c = [Math.cos(r[0]), Math.cos(r[1]), Math.cos(r[2])],
            s = [Math.sin(r[0]), Math.sin(r[1]), Math.sin(r[2])];
    
        return new Quaternion(c[0]*c[1]*c[2] - s[0]*s[1]*s[2],
                              s[0]*c[1]*c[2] - c[0]*s[1]*s[2],
                              c[0]*s[1]*c[2] + s[0]*c[1]*s[2],
                              c[0]*c[1]*s[2] + s[0]*s[1]*c[2]);
    }

    ComputeQuaternion(alpha, beta, gamma) {
        // Convert Tait-Bryan angles to quaternion
        var quaternion = this.TaitBryanToQuaternion(alpha, beta, gamma);
        // Apply world transform
        quaternion = quaternion.Multiply(new Quaternion(Math.sqrt(0.5), -Math.sqrt(0.5), 0, 0));
        // Apply screen transform
        var angle = window.orientation ? -window.orientation * Math.PI / 180 / 2 : 0;
        return quaternion.Multiply(new Quaternion(Math.cos(angle), 0, -Math.sin(angle), 0));
    }
}

document.querySelectorAll('.panorama-viewer:not(.binded)').forEach((el) => {
    if (typeof window.atruviaPanoramas === "undefined") {
        window.atruviaPanoramas = [];
    }
    window.atruviaPanoramas[el.id] = new PanoramaViewer(el);
});