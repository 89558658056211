import { popperGenerator } from '@popperjs/core/lib/popper-lite';

import applyStyles from '@popperjs/core/lib/modifiers/applyStyles';
import computeStyles from '@popperjs/core/lib/modifiers/computeStyles';
import popperOffsets from '@popperjs/core/lib/modifiers/popperOffsets';
import eventListeners from '@popperjs/core/lib/modifiers/eventListeners';
import offset from '@popperjs/core/lib/modifiers/offset';
import flip from '@popperjs/core/lib/modifiers/flip';
import arrow from '@popperjs/core/lib/modifiers/arrow';
import preventOverflow from '@popperjs/core/lib/modifiers/preventOverflow';

const createPopper = popperGenerator({
  defaultModifiers: [
    eventListeners,
    applyStyles,
    computeStyles,
    popperOffsets,
    flip,
    offset,
    arrow,
    preventOverflow,
  ],
});

export default createPopper;
