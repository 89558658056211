import elementIndex from './elementIndex';

type Direction = 'prev' | 'next';
type OnUpdateCallable = (currentSlide: number) => void;

interface Slider {
  move(direction: Direction): void;
  moveTo(targetSlide: number, direction: Direction): void;
  onUpdate(callback: OnUpdateCallable): void;
  destroy(): void;
}

const createSlider = (
  $container: HTMLElement,
  childrenSelector: string,
): Slider => {
  const callbacks: OnUpdateCallable[] = [];
  let currentSlide = 0;

  const items = Array.from(
    $container.querySelectorAll<HTMLElement>(childrenSelector),
  );

  const getTargetSlide = (direction: Direction) => {
    // Get target slide
    let targetSlide =
      direction === 'prev' ? currentSlide - 1 : currentSlide + 1;

    if (targetSlide < 0) {
      targetSlide = items.length - 1;
    } else if (targetSlide > items.length - 1) {
      targetSlide = 0;
    }

    return targetSlide;
  };

  const onUpdate = (callback: OnUpdateCallable) => {
    callbacks.push(callback);
  };

  const updateCurrentSlide = (newCurrentSlide: number) => {
    currentSlide = newCurrentSlide;
    callbacks.forEach((callback) => callback(currentSlide));
  };

  const moveTo = (targetSlide: number, direction: Direction = 'next') => {
    if (targetSlide < 0 || targetSlide > items.length - 1) {
      throw new RangeError();
    }

    if (targetSlide === currentSlide) {
      return;
    }

    // Reset after transition has finished
    const afterTransition = () => {
      // Remove event
      $container.removeEventListener('transitionend', afterTransition);

      // Reset position
      $container.style.transition = 'none';

      requestAnimationFrame(() => {
        $container.style.transform = 'translateX(0)';

        // Update order
        items.forEach(($item, index) => {
          if (index === targetSlide) {
            $item.style.order = '0';
          } else {
            $item.style.order = '1';
          }
        });

        // Restore transitions
        requestAnimationFrame(() => {
          $container.style.transition = '';
        });
      });
    };

    $container.addEventListener('transitionend', afterTransition);

    // Start pre-moving
    $container.style.transition = 'none';

    requestAnimationFrame(() => {
      // Update order for sliding
      $container.style.transform = `translateX(${
        direction === 'prev' ? '-100%' : '0%'
      })`;

      items.forEach(($item, index) => {
        if (index === targetSlide) {
          $item.style.order = direction === 'prev' ? '-1' : '1';
        } else if (index === currentSlide) {
          $item.style.order = '0';
        } else {
          $item.style.order = '2';
        }
      });

      // Move slider
      requestAnimationFrame(() => {
        $container.style.transition = '';
        $container.style.transform = `translateX(${
          direction === 'prev' ? '0%' : '-100%'
        })`;

        updateCurrentSlide(targetSlide);
      });
    });
  };

  const move = (direction: Direction) => {
    moveTo(getTargetSlide(direction), direction);
  };

  // React to touch events
  const onTouchstart = (startEvent: TouchEvent) => {
    const { width } = $container.getBoundingClientRect();
    const { clientX: startPosition } = startEvent.touches[0];
    const tripPoint = width / 3;

    let targetSlide: number;
    let direction: Direction;
    let offset: string;
    let hasReachedTripPoint = false;

    const onTouchmove = (moveEvent: TouchEvent) => {
      const { clientX } = moveEvent.touches[0];
      const diff = startPosition - clientX;

      if (!targetSlide) {
        direction = diff < 0 ? 'prev' : 'next';
        targetSlide = getTargetSlide(direction);
        offset = direction === 'prev' ? '-100%' : '0%';

        items.forEach(($item, index) => {
          if (index === targetSlide) {
            $item.style.order = direction === 'prev' ? '-1' : '1';
          } else if (index === currentSlide) {
            $item.style.order = '0';
          } else {
            $item.style.order = '2';
          }
        });
      }

      $container.style.transform = `translateX(calc(${offset} + ${
        diff * -1
      }px))`;

      if (Math.abs(diff) > tripPoint) {
        hasReachedTripPoint = true;
      }
    };

    const afterTransition = () => {
      // Remove event
      $container.removeEventListener('transitionend', afterTransition);

      // Reset position
      $container.style.transition = 'none';

      requestAnimationFrame(() => {
        $container.style.transform = 'translateX(0)';

        // Update order
        items.forEach(($item, index) => {
          if (index === targetSlide) {
            $item.style.order = '0';
          } else {
            $item.style.order = '1';
          }
        });

        // Restore transitions
        requestAnimationFrame(() => {
          $container.style.transition = '';
        });
      });
    };

    const onTouchend = () => {
      // Target slide is current slide of trip point hasn't been reached
      if (!hasReachedTripPoint) {
        targetSlide = currentSlide;
      } else {
        offset = direction === 'prev' ? '0%' : '-100%';
        updateCurrentSlide(targetSlide);
      }

      // Reset after transition has finished
      $container.addEventListener('transitionend', afterTransition);

      // Snapback
      $container.style.transition = '';
      $container.style.transform = `translateX(${offset})`;

      // Remove all events
      $container.removeEventListener('touchmove', onTouchmove);
      $container.removeEventListener('touchend', onTouchend);
      $container.removeEventListener('touchend', onTouchend);
      $container.removeEventListener('touchcancel', onTouchend);
    };

    // Disable transition for now
    $container.style.transition = 'none';

    // Enable events
    $container.addEventListener('touchmove', onTouchmove);
    $container.addEventListener('touchend', onTouchend);
    $container.addEventListener('touchcancel', onTouchend);
  };

  $container.addEventListener('touchstart', onTouchstart, {
    passive: true,
  });

  // Catch focus events
  const onFocusin = (event: FocusEvent) => {
    const $slide =
      event.target instanceof HTMLElement
        ? event.target?.closest(childrenSelector)
        : null;

    if ($slide) {
      if ($container.parentNode instanceof HTMLElement) {
        $container.parentNode.scrollLeft = 0;
      }

      const index = elementIndex($slide);
      moveTo(index);
    }
  };

  $container.addEventListener('focusin', onFocusin);

  const destroy = () => {
    // Remove events
    $container.removeEventListener('touchstart', onTouchstart);
    $container.removeEventListener('focusin', onFocusin);

    // Reset position
    $container.style.transform = '';
    items.forEach(($item) => {
      $item.style.order = '';
    });
  };

  return { move, moveTo, onUpdate, destroy };
};

export default createSlider;
