import { createFocusTrap, FocusTrap } from 'focus-trap';
import { on } from 'delegated-events';
import { Instance } from '@popperjs/core';
import invisibleFocus from '../../../javascripts/utils/invisibleFocus';
import createPopper from '../../../javascripts/utils/createPopper';

let focusTrap: FocusTrap;
let popper: Instance;

on('click', '.dropdown-navigation__button', (event) => {
  const { currentTarget: $button } = event;
  const $children = $button.parentNode?.querySelector<HTMLElement>(
    '.dropdown-navigation__children',
  );

  if ($button.parentNode instanceof HTMLElement && $children) {
    event.preventDefault();

    if (!$children.hidden) {
      focusTrap?.deactivate();
    } else {
      $children.hidden = false;

      popper = createPopper($button, $children, {
        placement: 'bottom',
        strategy: 'fixed',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 20],
            },
          },
        ],
      });

      focusTrap = createFocusTrap($button.parentNode, {
        onDeactivate() {
          $children.hidden = true;
          popper?.destroy();
          invisibleFocus($button);
          $button.classList.remove('button--active');
        },
        initialFocus: '.dropdown-navigation__children',
        clickOutsideDeactivates: true,
        returnFocusOnDeactivate: false,
        preventScroll: false,
      });

      $button.classList.add('button--active');
      focusTrap.activate();
    }
  }
});
