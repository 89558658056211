import { createFocusTrap, FocusTrap } from 'focus-trap';
import { on } from 'delegated-events';
import { Instance } from '@popperjs/core';
import invisibleFocus from '../../../javascripts/utils/invisibleFocus';
import moveFocus from '../../../javascripts/utils/moveFocus';
import createPopper from '../../../javascripts/utils/createPopper';

let focusTrap: FocusTrap;
let popper: Instance;

on('change', '.dropdown-select', (event) => {
  const { currentTarget: $container } = event;
  const $buttonContainer = $container.querySelector<HTMLDivElement>(
    '.dropdown-select__button',
  );

  const selectedOptions = [];
  $container
    .querySelectorAll<HTMLInputElement>('.field__checkbox-input:checked')
    .forEach(($checkbox) => {
      $checkbox.labels?.forEach(($label) => {
        if ($label.textContent) {
          selectedOptions.push($label.textContent.trim());
        }
      });
    });

  $buttonContainer?.querySelector('.dropdown-select__counter')?.remove();
  if (selectedOptions.length > 0) {
    const $counter = document.createElement('div');
    $counter.className = 'dropdown-select__counter';
    $counter.textContent = selectedOptions.length.toFixed(0);
    $buttonContainer?.appendChild($counter);
  }
});

on('click', '.dropdown-select__button button.button', (event) => {
  const { currentTarget: $button } = event;
  const $container = $button.closest<HTMLElement>('.dropdown-select');
  const $checkboxes = $container?.querySelector<HTMLElement>(
    '.dropdown-select__checkboxes',
  );

  if (!$container || !$checkboxes) {
    return;
  }

  event.preventDefault();

  if (!$checkboxes.hidden) {
    focusTrap?.deactivate();
  } else {
    focusTrap = createFocusTrap($container, {
      onActivate() {
        $checkboxes.hidden = false;

        popper = createPopper($button, $checkboxes, {
          placement: 'bottom',
          strategy: 'fixed',
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 20],
              },
            },
            {
              name: 'preventOverflow',
              options: {
                padding: 20,
              },
            },
          ],
        });

        $button.classList.add('button--active');
        moveFocus($checkboxes);
      },
      onDeactivate() {
        $checkboxes.hidden = true;
        popper?.destroy();
        invisibleFocus($button);
        $button.classList.remove('button--active');
      },
      initialFocus: false,
      clickOutsideDeactivates: true,
      returnFocusOnDeactivate: false,
      preventScroll: false,
    });

    focusTrap.activate();
  }
});
