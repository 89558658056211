class CircleGraphic {
    constructor($el) {
        this.$el = $el;
        this.$circleEntries = this.$el.querySelectorAll('.circle-graphic__circle-entry');
        this.$circle = this.$el.querySelector('.circle-graphic__circle');
        this.$circleGraphic = this.$el.querySelector('.circle-graphic__graphic');

        this.cirlceCenterX = 50; // in percent
        this.circleCenterY = 50; // in percent
        this.circleRadius = 50; // in percent
        this.resizeDelay = 200;
        this.resizeInterval = undefined;

        this.InitEvents();
        this.CalculateEntries();
        this.CalculateBulletPoints();
        this.$el.classList.add('binded');
    }

    CalculateBulletPoints() {
        var self = this; 
        var circleWidth = self.$circle.getBoundingClientRect().width;
        var entriesCount = self.$circleEntries.length;
        var graphicWidth = self.$circleGraphic.getBoundingClientRect().width;
        var bulletPoint = self.$el.querySelector('.circle-graphic__circle-entry');
        var bulletPointWidth = bulletPoint ? bulletPoint.getBoundingClientRect().width : 0;
        var sideWidth = ((graphicWidth - circleWidth) / 2) - (bulletPointWidth/2);
        this.$circleEntries.forEach((circleEntry, index)=>{
            var angle = 360 / entriesCount * index;
            var bulletPoints = circleEntry.querySelector('.circle-graphic__circle-entry__bullet-points');
            if(bulletPoints){
                bulletPoints.style.width = sideWidth + 'px';
            }
            if(window.innerWidth > 768) {
                if(circleEntry.classList.contains('circle-graphic__circle-entry__bullet-points--bottom') && bulletPoints) {
                    self.$circleGraphic.style.paddingBottom = bulletPoints.getBoundingClientRect().height + 100 + 'px';
                }
                if(circleEntry.classList.contains('circle-graphic__circle-entry__bullet-points--top') && bulletPoints) {
                    self.$circleGraphic.style.paddingTop = bulletPoints.getBoundingClientRect().height + 'px';
                }
            } else {
                self.$circleGraphic.removeAttribute('style');
            }
        });
    }

    CalculateEntries() {
        var self = this;
        this.$circleEntries.forEach((circleEntry, index)=>{
            var entriesCount = self.$circleEntries.length;
            var angle = (360 / entriesCount * index) - 90; // -90 to rotate it to top counterclock-wise
            var radian = angle * (Math.PI / 180);
            var left = self.circleRadius * Math.cos(radian) + self.circleCenterY;
            var top = self.circleRadius * Math.sin(radian) + self.circleCenterY;
            circleEntry.style.left = left + '%';
            circleEntry.style.top = top + '%';
            var side = 'top';
            var mirrored = false;
            angle += 90; // re-normalize angle
            if(angle > 10 && angle < 170) {
                side = 'right';
            }
            if(angle > 190 && angle < 350) {
                side = 'left';
                mirrored = true;
            }
            if(angle > 0 && angle <= 10) {
                side = 'top-right';
            }
            if(angle >= 170 && angle < 180) {
                side = 'bottom-right';
            }
            if(angle == 180) {
                side = 'bottom';
                mirrored = true;
            }
            if(angle > 180 && angle <= 190) {
                side = 'bottom-left';
                mirrored = true;
            }
            if(angle >= 350 && angle < 360) {
                side = 'top-left';
                mirrored = true;
            }
            circleEntry.dataset.angle = angle;
            circleEntry.classList.add('circle-graphic__circle-entry__bullet-points--' + side);
            if(mirrored) {
                circleEntry.classList.add('circle-graphic__circle-entry__bullet-points--mirrored');
            }
        });
    }

    InitEvents() {
        var self = this;
        window.addEventListener('resize',()=>{
            window.clearInterval(self.resizeInterval);
            self.resizeInterval = window.setInterval(()=>{
                window.clearInterval(self.resizeInterval);
                self.CalculateBulletPoints();
            },self.resizeDelay);
        });
    }
}

document.querySelectorAll('.circle-graphic:not(.binded)').forEach((el) => {
    new CircleGraphic(el);
});