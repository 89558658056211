import cookies from 'js-cookie';
import { on } from 'delegated-events';
import whenReady from '../../../javascripts/utils/whenReady';

whenReady().then(() => {
  document
    .querySelectorAll<HTMLElement>('.alert-banner')
    .forEach(($alertBanner) => {
      // Without ID, alert banner can't be dismissed
      if (!$alertBanner.id) {
        return;
      }

      // Check if banner is dismissed
      if (!cookies.get(`alert-banner-${$alertBanner.id}`)) {
        $alertBanner.hidden = false;
      }
    });
});

on('click', 'button.alert-banner__dismiss-button', (event) => {
  const $alertBanner =
    event.currentTarget.closest<HTMLElement>('.alert-banner');

  if (!$alertBanner?.id) {
    return;
  }

  event.preventDefault();

  $alertBanner.hidden = true;
  cookies.set(`alert-banner-${$alertBanner.id}`, '1', {
    expires: 365,
    sameSite: 'Strict',
    secure: true,
  });
});
